<template>
  <div>
    <div>
      <div class="m-1">
        <h4>
          {{ widget.param.title }}
        </h4>
      </div>
      <cbs-collapse :trigger="isData()">
        <vue-apex-charts v-if="data"
          type="donut"
          height="400"
          :options="chartParam().chartOptions"
          :series="querySeries()"
        />
      </cbs-collapse>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import VueApexCharts from 'vue-apexcharts'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'CbsWidgetDonut',
  components: {
    CbsDebug,
    VueApexCharts,
    CbsCollapse,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadQuery()
    },
    isData() {
      return this.data !== null
    },
    loadQuery() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'widget',
          param: {
            widgetid: this.widget.id,
          },
        },
      })
        .then(response => {
          // console.log('query response', response)
          // this.query = response
          if (response.data.thread) this.threadQuery(response.data.thread)
        })
        .catch(errQry => {
          console.log('query error', errQry)
        })
    },
    threadQuery(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.data = response.data.thread.result.widget
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayQuery(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          } else {
            useCubus.toastError(this, 'Load control panel is aborted.')
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error.toString())
        })
    },
    delayQuery(thread) {
      setTimeout(() => this.threadQuery(thread), 500)
    },
    querySeries() {
      if (this.data) {
        return this.data.data.values
      }
      return []
    },
    queryCategories() {
      if (this.data) {
        return this.data.data.categories
      }
      return []
    },
    chartParam() {
      return {
        chartOptions: {
          legend: {
            show: true,
            position: 'right',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'TOTAL',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          // labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
          labels: this.queryCategories(),
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    },
    chartOptions() {
      return this.chartParam().chartOptions
    },
  },
}
</script>

<style scoped>

</style>
